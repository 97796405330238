<template>

  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="author" content="Theme Region">
    <meta name="description" content="">
    <title>Login page</title>
    <!-- Bootstrap CSS -->

    <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
    <link href="css/animate/animate.min.css" rel="stylesheet">
    <link rel="stylesheet" href="css/bootstrap.css">
    <!--	bootstrap css-->
    <!--  CSS -->
    <link rel="stylesheet" href="css/navigation-temp.css">
    <link rel="stylesheet" href="css/style.css">
    <!-- <link rel="stylesheet" href="css/style2.css"> -->

    <link rel="stylesheet" href="css/form.css">
  </head>

  <body>
  <section class="p-sm-0 p-5" id="service-form-section">
    <div class="login-bg">
      <div class="container p-sm-0 p-5">
        <row class="d-flex justify-content-center align-items-center align-content-center min-100vh">
          <div class="col-lg-6 col-md-9  login-wrap p-5 text-white">
            <div class="row login-header ad-profile section h-100">
              <ul class="user-menu col-12 w-100 text-uppercase toogle-loginfforms">

                <li @click="loginForm(true)"
                    :class="['py-2', 'float-left', { 'active-log': login }]"
                    class="show-login">
                  log in
                </li>

                <li @click="loginForm(false)"
                    :class="['py-2', 'float-left', 'ml-3', { 'active-log': !login }]"
                    class="show-signup">
                  sign up
                </li>


              </ul>
            </div>

            <div class="login col-12 p-0 text-uppercase">
              <center>
                <img :src="county.logo" class="e-logo mt-3 mx-3 mb-2" height="168px">
                <hr class="my-3">
              </center>

              <!-- Login Form -->
              <form v-if="login" class="col-12 px-0 login-form pt-3 animated" id="login-form">
                <div class="form-group col-sm-12 col-md-12 p-0">
                  <label for="email" class="text-capitalize">email address</label>
                  <input type="email" class="form-control" v-model="loginFormData.email" placeholder="email@email.com">
                </div>
                <div class="form-group col-sm-12 col-md-12 p-0 my-5">
                  <label for="password" class="text-capitalize">account password</label>
                  <input type="password" class="form-control" v-model="loginFormData.password" placeholder="enter password">
                </div>
                <div class="col-12 p-0">
                  <input id="check" type="checkbox" class="check" v-model="loginFormData.keepSignedIn" checked>
                  <label for="check" class="text-capitalize"><span class="icon"></span> Keep me Signed in</label>
                </div>
                <div v-if="message!=null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>


                <div class="col-12 p-0 text-uppercase">
                  <button type="button" @click="submitLogin" class="btn btn-primary text-white font-14 w-100 p-4 center mb-5">
                    <div class="btn-txt animated">
                      <span class="btn-text text-uppercase font-12">sign in</span>
                      <i data-feather="arrow-right" class="ml-2 float-right pull-right"></i>
                    </div>
                    <div class="lds-ellipsis d-none animated">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
                <hr class="mt-5">
                <p><center><a @click="resetPassword" class="text-center text-capitalize">Forgot Password?</a></center></p>
              </form>

              <!-- Signup Form -->
              <form v-if="!login" class="col-12 px-0 login-form pt-3 animated" id="signup-form">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="fname" class="text-capitalize">first name</label>
                    <input type="text" class="form-control" v-model="signupFormData.firstName" placeholder="first name eg John">
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="other-names" class="text-capitalize">other name(s)</label>
                    <input type="text" class="form-control" v-model="signupFormData.otherNames" placeholder="other name eg Doe">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="email" class="text-capitalize">Email address</label>
                    <input type="email" class="form-control" v-model="signupFormData.email" placeholder="example@email.com">
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="phone" class="text-capitalize">Enter your phone number</label>
                    <input type="text" class="form-control" v-model="signupFormData.phone" placeholder="eg 071 ...">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="pass" class="text-capitalize">create password</label>
                    <input type="password" class="form-control" v-model="signupFormData.password" placeholder="password">
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <label for="confirm" class="text-capitalize">confirm password</label>
                    <input type="password" class="form-control" v-model="signupFormData.confirmPassword" placeholder="confirm password">
                  </div>
                </div>
                <div class="col-12 p-0 text-uppercase my-5">
                  <button type="button" @click="submitSignup" class="btn btn-primary text-white font-14 w-100 p-4 center mb-5">
                    <div class="btn-txt animated">
                      <span class="btn-text text-uppercase font-12">create account</span>
                      <i data-feather="arrow-right" class="ml-2 float-right pull-right"></i>
                    </div>
                    <div class="lds-ellipsis d-none animated">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </button>
                </div>
              </form>
            </div>


          </div>
        </row>
      </div>
    </div>
  </section>

  <!--scripts-->
  </body>



</template>

<script>

import {authUrl, execute} from "@/api";

export default {
  name: "FooterBar",
  data() {
    return{
      message:null,
      login: true,
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        QRLink:null,
        ussdCode:null,
        mobileApp:null
      },
      loginFormData: {
        email: '',
        password: '',
        keepSignedIn: true
      },
      signupFormData: {
        firstName: '',
        otherNames: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
      },
      customer: {
        id: null,
        names: null,
        phoneNumber: null,
        email: null,
        createdBy: null,
        dateCreated: null,
        password: null
      },
      alert:{
        success: false,
        info: false,
        failed:false
      },

    }},
  mounted: function () {
    this.getCounty()
  },
  methods:{

    getDeviceDetails() {
      // Operating System
      if (navigator.userAgent.indexOf('Win') !== -1) this.os = 'Windows';
      else if (navigator.userAgent.indexOf('Mac') !== -1) this.os = 'MacOS';
      else if (navigator.userAgent.indexOf('Linux') !== -1) this.os = 'Linux';
      else this.os = 'Unknown';

      // Browser
      if (navigator.userAgent.indexOf('Chrome') !== -1) this.browser = 'Chrome';
      else if (navigator.userAgent.indexOf('Firefox') !== -1) this.browser = 'Firefox';
      else if (navigator.userAgent.indexOf('Safari') !== -1) this.browser = 'Safari';
      else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode) this.browser = 'Internet Explorer';
      else this.browser = 'Unknown';

      // Screen Resolution
      this.screenResolution = `${window.screen.width} x ${window.screen.height}`;

      // CPU Cores
      this.cpuCores = navigator.hardwareConcurrency || 'Unknown';

      // Memory
      this.memory = (navigator.deviceMemory || 'Unknown');

      this.deviceDetials = `os: ${this.os} | browser: ${this.browser} | screenResolution: ${this.screenResolution} | cpuCores: ${this.cpuCores} | memory: ${this.memory}`

    },

    getCounty(){
      this.message = null
      const data = new FormData();
      data.append("function", "getCounty");
      execute(data,authUrl).then((res) =>{
            if (res.data.success) {
              this.county = res.data.data.county
            }else{
              // alert(res.data.message)
            }
          }).catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    },
    loginForm(value){
      this.login = value
    },
    gotTo(route){
      this.$router.push(route);
    },
    submitLogin() {
      this.message = null
      const data = new FormData();
      data.append("function", "submitLogin");
      data.append("loginFormData", JSON.stringify(this.loginFormData));
      data.append("deviceDetails", this.getDeviceDetails);
      execute(data,authUrl).then((res) =>{
        if (res.data.success) {
          this.message = null
          this.gotTo('home-page')
        }else{
          this.message = res.data.message
        }
      }).catch((e) => {
        //alert(e.message);
        this.message =e.message
      });
    },
    submitSignup() {
      // Check if the passwords match
      if (this.signupFormData.password !== this.signupFormData.confirmPassword) {
        alert("Passwords do not match! Please re-enter.");
        return; // Stop form submission if passwords don't match
      }

      // If passwords match, proceed with form submission
      const data = new FormData();
      data.append("function", "submitSignup");
      data.append("signupFormData", JSON.stringify(this.signupFormData));

      execute(data, authUrl)
          .then((res) => {
            if (res.data.success) {
              // Handle successful signup
              this.customer = res.data.data.customer;
              this.loginFormData.email = this.customer.email
              this.login = true

            } else {
              // Handle signup failure
              alert(res.data.message);
            }
          })
          .catch((e) => {
            // Handle any errors during request
            this.message = e.message;
            alert(e.message);
          });
    },
    resetPassword() {
      this.message = null;

      if (!this.loginFormData.email || this.loginFormData.email.trim() === '') {
        // Handle the case where the email is empty
        this.message = "Please enter your email";
      } else {
        // Put a confirmation alert
        const confirmation = window.confirm("Are you sure you want to reset your password?");

        if (confirmation) {
          // Proceed with password reset logic if user confirms
         this.resetPasswordSelfService()
        } else {
          // Handle the case where the user cancels the confirmation
          console.log("Password reset canceled");
        }
      }
    },

    resetPasswordSelfService() {
      // If passwords match, proceed with form submission
      const data = new FormData();
      data.append("function", "resetPasswordSelfService");
      data.append("loginFormData", JSON.stringify(this.loginFormData));

      execute(data, authUrl)
          .then((res) => {
            if (res.data.success) {

              alert(res.data.message);

            } else {
              // Handle signup failure
              alert(res.data.message);
            }
          })
          .catch((e) => {
            // Handle any errors during request
            this.message = e.message;
            alert(e.message);
          });
    },



  }

}
</script>



<style scoped>

</style>