import {createWebHashHistory, createRouter} from "vue-router";

import HomePage from "@/components/HomePage.vue";
import DailyParking from "@/components/parking/DailyParking.vue";
import PrintReceipt from "../../public/PrintDocs/Receipts/PrintReceipt.vue";
import CreateBusiness from "@/components/sbp/CreateBusiness";
import BusinessPayment from "@/components/sbp/BusinessPayment";
import HouseRents from "@/components/rent/HouseRents";
import PrintPermit from "@/components/sbp/PrintPermit";
import Permit from "../../public/PrintDocs/permit/Permit";
import LandRate from "@/components/landrate/LandRate";
import PayBill from "@/components/paybill/PayBill";
import LoginPage from "@/components/LoginPage.vue";

const routes =[

    {
        path:"/",
        name:"LoginPage",
        component: LoginPage,
        meta:{public: true}
    },

    {
        path:"/home-page",
        name:"HomePage",
        component: HomePage,
        meta:{public: true}
    },
    {
        path:"/daily-parking",
        name:"DailyParking",
        component: DailyParking,
        meta:{public: true}
    },
    {
        path:"/pay-bill",
        name:"pay-bill",
        component: PayBill,
        meta:{public: true}
    },
    {
        path:"/house-rents",
        name:"house-rents",
        component: HouseRents,
        meta:{public: true}
    },
    {
        path:"/land-rate",
        name:"land-rate",
        component: LandRate,
        meta:{public: true}
    },

    {
        path: "/receipt",
        name: "receipt",
        component: PrintReceipt,
        meta: {public: true}
    },
    {
        path: "/create-business",
        name: "create-business",
        component: CreateBusiness,
        meta: {public: true}
    },
    {
        path: "/sbp-payment",
        name: "business-payment",
        component: BusinessPayment,
        meta: {public: true}
    },
    {
        path: "/print-permit",
        name: "print-permit",
        component: PrintPermit,
        meta: {public: true}
    },
    {
        path: "/permit",
        name: "permit",
        component: Permit,
        meta: {public: true}
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
});



/*
router.beforeEach((to, from, next) => {
// check if the target route requires authentication
    if (!to.meta.public) {
// check if the user is logged in or not
        if (sessionStorage.getItem("isLoggedIn")) {
// if yes, allow the navigation
            next();
        } else {
// if no, redirect the user to the login page
            next("/");
        }
    } else {
// if the target route does not require authentication, allow the navigation
        next();
    }
});
*/



export default router;
